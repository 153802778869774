<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end" />
    <app-collapse
      accordion
    >

      <app-collapse-item
        title="Information générales"
      >

        <!-- form -->

        <!-- form -->
        <validation-observer
          v-if="teacher"
          ref="simpleRules"
        >
          <b-form>
            <b-row>

              <b-col md="6">
                <b-form-group
                  label="Nom"
                  label-for="Nom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Nom"
                    rules="required"
                  >
                    <b-form-input
                      v-model="teacher.first_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Nom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Prénom"
                  label-for="Prénom"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Prénom"
                    rules="required"
                  >
                    <b-form-input
                      v-model="teacher.last_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Prénom"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="Email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      v-model="teacher.email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  label="Mot de passe"
                  label-for="a-password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="Password"
                    rules="required|min:8"
                  >
                    <b-form-input
                      id="a-password"
                      v-model="teacher.password"
                      :state="errors.length > 0 ? false : null"
                      type="password"
                      placeholder="Password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Cin"
                  label-for="Cin"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cin"
                    rules="required"
                  >
                    <b-form-input
                      v-model="teacher.cin"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Cin"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="Téléphone"
                  label-for="Téléphone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Téléphone"
                    rules="required"
                  >
                    <b-form-input
                      v-model="teacher.phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Téléphone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Adresse"
                  label-for="Adresse"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Adresse"
                    rules="required"
                  >
                    <b-form-input
                      v-model="teacher.address"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Adresse"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>

          </b-form>
        </validation-observer>
      </app-collapse-item>

      <app-collapse-item title="Dossier Administrative">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                label="Etablissement D'origine"
                label-for="Etablissement D'origine"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Etablissement D'origine"
                  rules="required"
                >
                  <b-form-input
                    v-model="teacher.insituation"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Etablissement D'origine"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Diplome"
                label-for="Diplome"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Diplome"
                  rules="required"
                >
                  <b-form-input
                    v-model="teacher.diploma"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Diplome"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="grades"
                label-for="Chercheur"
              >
                <validation-provider
                  #default="{ errors }"
                  name="grades"
                  rules="required"
                >
                  <v-select
                    v-model="teacher.grade"
                    :clearable="false"
                    placeholder="Chercheur"
                    label="label"
                    :options="grades"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Statut"
                label-for="Statut"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Statut"
                  rules="required"
                >
                  <v-select
                    v-model="teacher.status"
                    :clearable="false"
                    placeholder="vacataire"
                    label="geade"
                    :options="status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Département"
                label-for="Département"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Département"
                  rules="required"
                >
                  <v-select
                    v-model="teacher.department"
                    :clearable="false"
                    placeholder="department"
                    label="name"
                    :options="departments"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Etat"
                label-for="Etat"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Etat"
                  rules="required"
                >
                  <v-select
                    v-model="teacher.state"
                    :clearable="false"
                    placeholder="Etat"
                    label="Etat"
                    :options="states"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                label="Rib"
                label-for="Rib"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Rib"
                  rules="required"
                >
                  <b-form-input
                    v-model="teacher.rib"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Rib"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </app-collapse-item>

    </app-collapse>

    <div class="mt-2">
      <b-button
        variant="primary"
        type="submit"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        @click="addTeacher"
      >
        Modifier
      </b-button>

    </div>

  </div>
</template>
<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    AppCollapse,
    AppCollapseItem,
    vSelect,
    BButton,
  },
  data() {
    return {
      password: '',
      status: ['Vacataire', 'Contractuel', 'Permanent', 'Expert', 'Visiteur'],
      states: ['Activé', 'Non activé'],
      searchers: ['Chercheur', 'Non chercheur'],
      required,
      grades: [],
      departments: [],
      teacher: {},

    }
  },
  created() {
    this.getGrades()
    this.getDepartements()
    this.getTeacher()
  },
  methods: {
    async getGrades() {
      const response = await axios.get('/api/grades/')
      this.grades = response.data
    },
    async getDepartements() {
      const response = await axios.get('/api/departments/')
      this.departments = response.data
    },
    async getTeacher() {
      this.load = 'true'
      const { id } = this.$route.params
      const response = await axios.get(`/api/user/detail/${id}`)
      this.teacher = response.data
      this.load = 'false'
    },
    async addTeacher() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            await axios
              .post(
                '/api/authentication/register/',
                {
                  username: `${this.teacher.first_name} ${this.teacher.last_name}`,
                  email: this.teacher.email,
                  cin: this.teacher.cin,
                  phone: this.teacher.phone,
                  address: this.teacher.address,
                  diplome: this.teacher.diplome,
                  insituation: this.teacher.etablisement,
                  state: this.teacher.state,
                  status: this.teacher.status,
                  diploma: this.teacher.diploma,
                  price_1h_license: this.teacher.price_1h_icense,
                  price_1h_master: this.teacher.price_1h_master,
                  rib: this.teacher.rib,
                  searcher: this.teacher.searcher,
                  password: this.teacher.cin,
                  grade: this.teacher.grade.id,
                  department: this.teacher.department.id,
                  role: 'teacher',
                },
              )
            this.$router.push('/teachers')
            setTimeout(() => {
              this.showToast('success', 'top-center', 'information ajouté avec succés')
            }, 1000)
          } catch (err) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', err.toString())
            }, 1000)
          }
        }
      })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },

  },
}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
